import '~/js/libs/jquery.countdown.min';
import moment from 'moment';

const $countDown = $('.js-dsc-count');

const newDate = '2024-09-30';

let startDate = ('date' in sandals_app.page.countdown) ? sandals_app.page.countdown.date : newDate;

if (moment(startDate).isBefore(newDate)) {
    startDate = newDate;
  //startDate = newDate;
}

const date = moment(newDate, 'YYYY-MM-DD');

$countDown.countdown(date.format('YYYY-MM-DD'), event => {
    let days = event.strftime('%D');

    if (days === '01') {
        days = 'hurry! ' + days + ' day left';
    } else {
        days = 'hurry! ' + days + ' days left';
    }
    $('.dsc-counter>.hurry-message').html(days);

    const daysTxt = (event.strftime('%D') === '01') ? 'day' : 'days';
    const hoursTxt = (event.strftime('%H') === '01') ? 'hour' : 'hours';

    $(event.currentTarget).html(event.strftime(''
    + '<div><span class="count-digits">%D</span> ' + daysTxt + '</div>'
    + '<div><span class="count-digits">%H</span> ' + hoursTxt + '</div>'
    + '<div><span class="count-digits">%M</span> min.</div>'
    + '<div><span class="count-digits">%S</span> sec.</div>'));
});
